import { useEffect, useState } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useDownloadCertificate from '../../../hooks/useDownloadCertificate';

import {
  fetchParticipantResponses,
  fetchParticipantSessionDetails
} from '../../../actions/participantActions';
import { withdrawFromSession } from '../../../actions/sessionAction';

import { Col, Row } from '../../common/Grid';
import * as T from '../../common/Typography';
import * as S from './style';

import {
  INDIVIDUAL_EVENT,
  PARTICIPANT_DASHBOARD,
  SURVEY_QUESTIONS_URL
} from '../../../constants/navigationRoutes';
import Layout from '../../Layouts';

import { surveyTypesNew } from '../../../constants/index';
import { checkIfSessionIsFinished } from '../../../helpers/index';
import AccountPageHeader from '../../common/AccountPageHeader/index';
import AddToCalendarButton from '../../common/AddToCalendarButton';
import Icon from '../../common/Icon';
import Modal from '../../common/modal';
import Certificates from '../Certificates';

const ParticipantSessionDetails = ({
  session = {},
  responses = [],
  fetchSessionDetails,
  loaded,
  fetchParticipantResponses,
  withdrawFromSession,
  withdrawFromSessionLoading,
  userId
}) => {
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showWithdrawSuccessModal, setShowWithdrawSuccessModal] = useState(
    false
  );
  const history = useHistory();
  const { id } = useParams();
  const { downloading } = useDownloadCertificate();

  useEffect(() => {
    if (id.length !== 24) {
      history.push('/404');
    } else {
      fetchSessionDetails(id, history, true);
      fetchParticipantResponses();
    }
  }, [fetchParticipantResponses, fetchSessionDetails, history, id]);

  const getFullAddress = address => {
    if (!address) return 'N/A';
    const { addressLine1, addressLine2, town, postcode } = address;

    return [addressLine1, addressLine2, town, postcode]
      .filter(item => !!item)
      .join(', ');
  };

  const { preEventMaterials = [], postEventMaterials = [] } =
    session?.modules?.reduce(
      (acc, { preCourseMaterials = [], postCourseMaterials = [] } = {}) => {
        acc.preEventMaterials = [
          ...acc.preEventMaterials,
          ...preCourseMaterials
        ];
        acc.postEventMaterials = [
          ...acc.postEventMaterials,
          ...postCourseMaterials
        ];
        return acc;
      },
      { preEventMaterials: [], postEventMaterials: [] }
    ) || {};

  const sessionProgrammes =
    session.programmes?.map(({ _id }) => _id.toString()) || [];

  const eventHasPreEvaluation = session?.programmes?.some(
    programme => programme.includePreEvaluationQuestion
  );
  const eventHasPostEvaluation = session?.modules?.some(
    programme => programme.showPostCourseEvaluation
  );

  const filledPreEvaluation = responses
    ?.filter(res => res.surveyType === surveyTypesNew.PRE)
    ?.some(res => {
      const responseProgrammes = res.programmes?.map(String);
      return sessionProgrammes.every(programmeId =>
        responseProgrammes.includes(programmeId)
      );
    });

  let filledPostEvaluation = responses?.some(
    res =>
      res.surveyType === surveyTypesNew.POST &&
      res.session === session._id?.toString()
  );

  const handleClickEvaluation = ({ type }) => {
    history.push(
      SURVEY_QUESTIONS_URL.replace(':type', type)
        .replace(':id', session.shortId)
        .replace(':step', 0)
    );
  };

  const handleOpenEventPage = () => {
    history.push(INDIVIDUAL_EVENT.replace(':id', session?._id));
  };

  const isOnline = session?.format === 'Remote / Online';
  const isEventFinished = checkIfSessionIsFinished(session);
  const isOnWaitingList = !!session?.participantsEmails?.find(
    participant =>
      participant.user === userId && participant.status === 'waiting'
  );

  const checkIfWithdrawAllowed = () => {
    const sessionDate = moment(session?.date).startOf('day');
    const today = moment().startOf('day');
    return sessionDate.isAfter(today);
  };

  const handleWithdraw = async () => {
    await withdrawFromSession({
      sessionId: session?._id
    });
    setShowWithdrawModal(false);
    setShowWithdrawSuccessModal(true);
  };

  if (!loaded) return <div>Loading</div>;

  return (
    <Layout>
      <Row mb={2}>
        <Icon
          icon="chevron"
          direction="left"
          color="black"
          width="20"
          height="20"
        />
        <S.LinkWrapper
          onClick={history.goBack}
          aria-label="Back"
          role="back link"
        >
          Back
        </S.LinkWrapper>
      </Row>
      <AccountPageHeader
        title={session.title}
        subtitle={
          <Row ai="center">
            <Icon
              icon="openInNew"
              text={
                <S.LinkWrapper
                  underlined={true}
                  mb={7}
                  onClick={handleOpenEventPage}
                >
                  <T.P weight={600} small>
                    View event page
                  </T.P>
                </S.LinkWrapper>
              }
            />
          </Row>
        }
      />
      {isOnWaitingList ? (
        <Row mb={1}>
          <T.P>
            You are currently on the waiting list. As soon as a space is
            available you will be notified via email and will be able to access
            all event details on this page.
          </T.P>
        </Row>
      ) : (
        <>
          <Row>
            <AddToCalendarButton
              startTime={session.startTime}
              endTime={session.endTime}
              startDate={session.date}
              endDate={session.endDate}
              title={session?.title}
              session={session}
              location={
                isOnline ? session.meetingLink : getFullAddress(session.address)
              }
            />
          </Row>
          <Row mb={7} mt={6}>
            <S.Wrapper>
              <Row mb="4">
                <S.StyledCol w={[1.5, 4, 3]}>
                  <T.P smallMedium weight={700}>
                    Date
                  </T.P>
                </S.StyledCol>
                <Col w={[2.5, 6, 9]} as="center">
                  <T.P>
                    {session?.date
                      ? moment(session.date).format('DD-MM-YYYY')
                      : 'N/A'}
                  </T.P>
                </Col>
              </Row>
              {!!session?.endDate && (
                <Row mb="4">
                  <S.StyledCol w={[1.5, 4, 3]}>
                    <T.P smallMedium weight={700}>
                      End Date
                    </T.P>
                  </S.StyledCol>
                  <Col w={[2.5, 6, 9]} as="center">
                    <T.P>{moment(session.endDate).format('DD-MM-YYYY')}</T.P>
                  </Col>
                </Row>
              )}
              {!!session?.startTime && (
                <Row mb="4">
                  <S.StyledCol w={[1.5, 4, 3]}>
                    <T.P smallMedium weight={700}>
                      Time
                    </T.P>
                  </S.StyledCol>
                  <Col w={[2.5, 6, 9]} as="center">
                    <T.P>
                      {moment(session.startTime, 'HH:mm')
                        .format('h:mma')
                        .toLowerCase()}
                      {session?.endTime &&
                        ` to ${moment(session.endTime, 'HH:mm')
                          .format('h:mma')
                          .toLowerCase()}`}
                    </T.P>
                  </Col>
                </Row>
              )}
              <Row mb="4">
                <S.StyledCol w={[1.5, 4, 3]}>
                  <T.P smallMedium weight={700}>
                    Location
                  </T.P>
                </S.StyledCol>
                <Col w={[2.5, 6, 9]} as="center">
                  <T.P>
                    {isOnline ? 'Online' : getFullAddress(session.address)}
                  </T.P>
                </Col>
              </Row>
              {isOnline && (
                <Row mb="4">
                  <S.StyledCol w={[1.5, 4, 3]}>
                    <T.P smallMedium weight={700}>
                      Meeting link
                    </T.P>
                  </S.StyledCol>
                  <Col w={[2.5, 6, 9]} as="center">
                    {session?.meetingLink ? (
                      <T.Link
                        external
                        href={session.meetingLink}
                        color="darkGray"
                      >
                        {session.meetingLink}
                      </T.Link>
                    ) : (
                      <T.P>N/A</T.P>
                    )}
                  </Col>
                </Row>
              )}
              {!!session?.trainers?.length && (
                <Row mb="4">
                  <S.StyledCol w={[1.5, 4, 3]}>
                    <T.P smallMedium weight={700}>
                      Trainers
                    </T.P>
                  </S.StyledCol>
                  <Col w={[2.5, 6, 9]} as="center">
                    {session.trainers.map?.(({ name = '', _id = '' }) => (
                      <Row key={_id}>
                        <T.P>{name}</T.P>
                      </Row>
                    ))}
                  </Col>
                </Row>
              )}
              {!!session?.modules?.length && (
                <Row mb="4">
                  <S.StyledCol w={[1.5, 4, 3]}>
                    <T.P smallMedium weight={700}>
                      Modules
                    </T.P>
                  </S.StyledCol>
                  <Col w={[2.5, 6, 9]} as="center">
                    <T.P>
                      {session.modules.map?.(({ title = '', _id = '' }) => (
                        <Row key={_id}>
                          <T.P>{title}</T.P>
                        </Row>
                      ))}
                    </T.P>
                  </Col>
                </Row>
              )}
              {session?.level && (
                <Row mb="4">
                  <S.StyledCol w={[1.5, 4, 3]}>
                    <T.P smallMedium weight={700}>
                      Level
                    </T.P>
                  </S.StyledCol>
                  <Col w={[2.5, 6, 9]} as="center">
                    <T.P>{session.level}</T.P>
                  </Col>
                </Row>
              )}
              {!!session?.audience && (
                <Row mb="4">
                  <S.StyledCol w={[1.5, 4, 3]}>
                    <T.P smallMedium weight={700}>
                      Audience
                    </T.P>
                  </S.StyledCol>
                  <Col w={[2.5, 6, 9]} as="center">
                    <T.P>{session.audience}</T.P>
                  </Col>
                </Row>
              )}
              <Row mb="4">
                <S.StyledCol w={[1.5, 4, 3]}>
                  <T.P smallMedium weight={700}>
                    Contact
                  </T.P>
                </S.StyledCol>
                <Col w={[2.5, 6, 9]} as="center">
                  {!!session?.contactEmail ? (
                    <T.Link
                      external
                      href={`mailto:${session.contactEmail}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      color="darkGray"
                    >
                      {session.contactEmail}
                    </T.Link>
                  ) : (
                    <T.P>N/A</T.P>
                  )}
                </Col>
              </Row>
              <Row>
                <S.StyledCol w={[1.5, 4, 3]}>
                  <T.P smallMedium weight={700}>
                    Joining info
                  </T.P>
                </S.StyledCol>
                <Col w={[2.5, 6, 9]} as="center">
                  <T.P>{session?.extraInfo || 'N/A'}</T.P>
                </Col>
              </Row>
            </S.Wrapper>
          </Row>
          <Row>
            <Row mb={6}>
              <T.H2>Event materials</T.H2>
              {eventHasPreEvaluation && !filledPreEvaluation ? (
                <Row mb={1}>
                  <Row mb={1}>
                    <T.P>
                      To access these materials you first need to complete the
                      pre-course evaluation.
                    </T.P>
                  </Row>
                  <S.StyledButton
                    label="Complete Pre-course Evaluation"
                    onClick={() =>
                      handleClickEvaluation({ type: surveyTypesNew.PRE })
                    }
                    type="primary"
                    mb={3}
                    disabled={downloading}
                    loading={downloading}
                  />
                </Row>
              ) : (
                <>
                  <Row mb={1}>
                    <T.P>
                      Here are the materials you need to look at before
                      attending the event.
                    </T.P>
                  </Row>
                  {!!preEventMaterials.length &&
                    preEventMaterials.map(({ title = '', link = '', _id }) => (
                      <Row mb={1} key={_id}>
                        <T.Link external href={link} color="darkGray">
                          {title || link}
                        </T.Link>
                      </Row>
                    ))}
                </>
              )}
            </Row>
            <Row mb={6}>
              <T.H2>Post-event materials</T.H2>
              {!isEventFinished ? (
                <Row mb={1}>
                  <T.P>These will become available after the event.</T.P>
                </Row>
              ) : (
                <>
                  {eventHasPostEvaluation && !filledPostEvaluation ? (
                    <Row mb={1}>
                      <Row mb={1}>
                        <T.P>
                          To access these materials you first need to complete
                          the post-course evaluation.
                        </T.P>
                      </Row>
                      <S.StyledButton
                        label="Complete Post Evaluation"
                        onClick={() =>
                          handleClickEvaluation({
                            type: surveyTypesNew.POST
                          })
                        }
                        type="primary"
                        mb={3}
                        disabled={downloading}
                        loading={downloading}
                      />
                    </Row>
                  ) : (
                    <>
                      <Row mb={1}>
                        <T.P>
                          Feel free to review any of the post-course materials
                          below.
                        </T.P>
                      </Row>
                      {!!postEventMaterials.length &&
                        postEventMaterials.map(
                          ({ title = '', link = '', _id }) => (
                            <Row mb={1} key={_id}>
                              <T.Link external href={link} color="darkGray">
                                {title || _id}
                              </T.Link>
                            </Row>
                          )
                        )}
                    </>
                  )}
                </>
              )}
            </Row>
            <Row mb={6}>
              <T.H2>Certificates</T.H2>
              {!isEventFinished ? (
                <Row mb={1}>
                  <T.P>
                    These will be available to download here after the event.
                  </T.P>
                </Row>
              ) : eventHasPostEvaluation ? (
                !filledPostEvaluation ? (
                  <Row mb={1}>
                    <T.P>
                      To access your certificates of completion you first need
                      to complete the post-course evaluation.{' '}
                    </T.P>
                  </Row>
                ) : (
                  <Certificates />
                )
              ) : (
                <Certificates />
              )}
            </Row>
          </Row>
        </>
      )}

      {checkIfWithdrawAllowed() && (
        <>
          <S.WithdrawWrapper>
            <S.StyledButton
              label="Withdraw from event"
              type="quartenary"
              onClick={() => setShowWithdrawModal(true)}
            />
          </S.WithdrawWrapper>
          <Modal
            type="confirm"
            cancelLabel="Cancel"
            visible={showWithdrawModal}
            setModalOpen={setShowWithdrawModal}
            onCancel={() => setShowWithdrawModal(false)}
            closeOnOK={false}
            loading={withdrawFromSessionLoading}
            parentFunc={handleWithdraw}
            text="This will remove the event from your account."
          />
          <Modal
            type="success"
            visible={showWithdrawSuccessModal}
            setModalOpen={setShowWithdrawSuccessModal}
            text="You have successfully withdrawn from this event."
            handleBack={() => history.push(PARTICIPANT_DASHBOARD)}
          />
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = state => ({
  session: state.participantSession?.participantSessionDetails?.sessionDetails,
  responses: state?.participantResponses?.participantResponses,
  loaded: state.participantSession.loaded,
  email: state.auth.email,
  sessions: state.sessions?.participantSessionsByEmail,
  withdrawFromSessionLoading: state.loading.withdrawFromSessionLoading,
  userId: state.auth.userId
});

const mapActionsToProps = {
  fetchSessionDetails: fetchParticipantSessionDetails,
  fetchParticipantResponses,
  withdrawFromSession
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ParticipantSessionDetails);
