import Icon from '../../../common/Icon';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { deleteSessionAction } from '../../../../actions/groupSessionsAction';
import { EDIT_SESSION_URL } from '../../../../constants/navigationRoutes';
import { customSwal } from '../../../../theme';
import {
  IconName,
  SessionAction,
  SessionActionsWrapper,
  SessionDelete,
  SessionEdit
} from './SessionActions.Style';

class SessionActions extends Component {
  deleteSession = _id => {
    const { deleteSessionAction: deleteSession, responses } = this.props;

    if (responses && responses.length > 0) {
      return Swal.fire({
        title: 'Cannot delete this session',
        text:
          'Sorry, this session has had evaluation responses, you have to delete registered attendees first (from "Manage Attendees" section).',
        ...customSwal
      });
    }

    Swal.fire({
      title: 'Are you sure that you want to delete this session?',
      text: "You won't be able to revert this!",
      confirmButtonText: 'Delete',
      showCancelButton: true,
      ...customSwal
    })
      .then(willDelete => {
        if (willDelete.value) {
          deleteSession(_id, this.props.history);
        }
      })
      .catch(() => {
        Swal.fire({
          title: 'Oops!',
          text: 'Something error in deleting this session',
          ...customSwal
        });
      });
  };

  render() {
    const { sessionDetails, isMobile } = this.props;
    const { _id, defaultSessionForCustomModule } = sessionDetails;
    const { deleteSession } = this;
    if (defaultSessionForCustomModule) return null;
    return (
      <SessionActionsWrapper>
        <SessionAction>
          <SessionEdit to={EDIT_SESSION_URL.replace(':sessionId', _id)}>
            <Icon icon="edit" color="gray" width="20" height="20" />
            <IconName>Edit {!isMobile && 'Event'}</IconName>
          </SessionEdit>
        </SessionAction>

        <SessionAction>
          <SessionDelete
            aria-label="Delete"
            onClick={() => deleteSession(_id, this.props.history)}
          >
            <Icon icon="trash" color="gray" width="20" height="20" />
            <IconName>Delete {!isMobile && 'Session'}</IconName>
          </SessionDelete>
        </SessionAction>
      </SessionActionsWrapper>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.checkBrowserWidth.isMobile,
  responses: state.sessions.sessionDetails.responses
});

export default connect(mapStateToProps, { deleteSessionAction })(
  SessionActions
);
