import * as Sentry from '@sentry/react';
import React, { Component } from 'react';
import CookieBot from 'react-cookiebot';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
// PLEASE DO NOT PUT ANTD STYLING SHEET HERE AS OVERRIDES EXISTING STYLES

import moment from 'moment';
import styled from 'styled-components';

import { checkAuth, setLastActive } from '../actions/authAction';
import { checkBrowserWidth } from '../actions/checkBrowserWidth';

import ScrollToTop from '../helpers/ScrollToTop';

import { userRoles } from '../constants';
import { mainTheme } from '../theme';
// PAGES
import Analytics from './pages/Analytics';
import Certificate from './pages/Certificate';
import ChangePassword from './pages/ChangePassword';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import UserDashboard from './pages/ParticipantDashboard';
import ParticipantProgress from './pages/ParticipantProgress';
import SessionDetails from './pages/SessionDetails';
import { ParticipantSignUp } from './pages/SignUp';
import Survey from './pages/Survey';
import SurveyResults from './pages/SurveyResults';
import UpsertSession from './pages/UpsertSession';
import ViewSessions from './pages/ViewSessions';
// import DecideView from './pages/DecideView';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ForgetPassword/ResetPassword';
import SessionsFiles from './pages/SessionsFiles';

import ChooseProgrammeForSessions from './pages/ChooseProgrammeForSessions';
import DecideRole from './pages/DecideRole';
import Unauthorized from './pages/ErrorPages/403';
import NotFound from './pages/ErrorPages/404';
import ServerError from './pages/ErrorPages/500';
import GroupBooking from './pages/Event/GroupBooking';
import Module from './pages/Module';
import Modules from './pages/Modules';
import ModuleSteps from './pages/ModuleSteps';
import MyAccount from './pages/MyAccount';
import MyProfile from './pages/MyProfile';
import ParticipantAssessment from './pages/ParticipantAssessment';
import ParticipantSessions from './pages/ParticipantSessions';
import PrintSessionDetails from './pages/SessionDetails/PrintSessionDetails';
import TrainerSessions from './pages/TrainerSessions';
import UpdateModule from './pages/UpdateModule';
import SessionCreated from './pages/UpsertSession/SessionCreated';

// COMPONENTS
import PrivateRoute from './common/PrivateRoute';

// ROUTES
import * as R from '../constants/navigationRoutes';

import AccessibilityGlobalStyles from '../theme/accessabilityGlobalStyle';
import Accessibility from './pages/Accessibility';
import Event from './pages/Event';
import Events from './pages/Events';
import InvitationSignUp from './pages/InvitationSignUp';
import ManageUsers from './pages/ManageUsers';
import ManageUsersAddEdit from './pages/ManageUsersAddEdit';
import ParticipantSessionDetails from './pages/ParticipantSessionDetails';
import Programme from './pages/Programme';
import ProgrammeCatalogue from './pages/ProgrammeCatalogue';
import ProgrammeCategories from './pages/ProgrammeCategories';
import ProgrammeCategoriesArchived from './pages/ProgrammeCategories/ArchivedProgrammeCategories';
import ProgrammeCategoriesAddEdit from './pages/ProgrammeCategoriesAddEdit';
import ProgrammeEdit from './pages/ProgrammeEdit';
import ProgrammeModules from './pages/ProgrammeModules';
import ProgrammeOverview from './pages/ProgrammeOverview';
import Programmes from './pages/Programmes';
import ProgrammeSelection from './pages/ProgrammeSelection';
import StoryBook, { Layout } from './pages/StoryBook';
import UnsubscribeNewsletter from './pages/UnsubscribeNewsletter';
import UpdateIncompleteProfile from './pages/UpdateIncompleteProfile';
import Register from './pages/Event/Register';

const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID;

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${mainTheme.colors.offWhite};
`;

class App extends Component {
  state = {
    width: window.innerWidth
  };

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentDidMount() {
    const {
      checkAuth: checkAuthActionCreator,
      checkBrowserWidth: checkBrowserWidthActionCreator,
      setLastActive
    } = this.props;
    const { width } = this.state;
    const isMobile = width <= 700;
    const isDeskTop = width >= 700;

    const data = {
      width,
      isMobile,
      isDeskTop
    };
    checkAuthActionCreator();
    checkBrowserWidthActionCreator(data);
    setLastActive();
  }

  componentDidUpdate() {
    const { checkBrowserWidth: checkBrowserWidthActionCreator } = this.props;

    const { width } = this.state;
    const isMobile = width <= 700;
    const isDeskTop = width >= 700;

    const data = {
      width,
      isMobile,
      isDeskTop
    };

    checkBrowserWidthActionCreator(data);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    return (
      <Wrapper id="wrapper">
        <a href="#main-content" className="skip-link">
          Skip to Main Content
        </a>
        <ThemeProvider theme={mainTheme}>
          {(this.props.layoutFontFamily || this.props.isFontLarge) && (
            <AccessibilityGlobalStyles
              fontFamily={this.props.layoutFontFamily}
              isFontLarge={this.props.isFontLarge}
            />
          )}
          <Router>
            <ScrollToTop />
            <Switch>
              {process.env.NODE_ENV === 'development' && (
                <Route path="/storybook" component={StoryBook} />
              )}
              {process.env.NODE_ENV === 'development' && (
                <Route
                  path="/layout"
                  component={() => (
                    <Layout layout="website">
                      <p>test</p>
                    </Layout>
                  )}
                />
              )}
              <PrivateRoute
                exact
                path={R.MANAGE_PROGRAMMES_PROGRAMME_MODULE_ADD}
                Component={UpdateModule}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MANAGE_PROGRAMMES_PROGRAMME_MODULE_EDIT}
                Component={UpdateModule}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path="/print/:sessionId"
                Component={PrintSessionDetails}
                allowedRoles={['trainer', 'admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.DECIDE_ROLE}
                Component={DecideRole}
                decideRole
              />
              <PrivateRoute
                exact
                path={R.EVAL_RESULTS}
                Component={SurveyResults}
                allowedRoles={['trainer', 'admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.HOME_URL}
                Component={Programmes}
                publicOnly
              />
              <PrivateRoute exact path={R.PROGRAMMES} Component={Programmes} />
              <PrivateRoute
                exact
                path={R.INDIVIDUAL_PROGRAMME}
                Component={Programme}
              />
              <PrivateRoute
                exact
                path={R.PROGRAMME_CATALOGUE}
                Component={ProgrammeCatalogue}
              />
              <PrivateRoute exact path={R.EVENTS_URL} Component={Events} />
              <PrivateRoute exact path={R.INDIVIDUAL_EVENT} Component={Event} />
              {/* OTHER PAGES */}
              <PrivateRoute
                exact
                path={R.PARTICIPANT_DASHBOARD}
                Component={UserDashboard}
                isPrivate
                allowedRoles={['participant']}
              />
              <PrivateRoute
                exact
                path="/reset-password/:token"
                Component={ResetPassword}
                publicOnly
              />
              <PrivateRoute exact path={R.MODULES_URL} Component={Modules} />
              <PrivateRoute exact path={R.MODULE_URL} Component={Module} />
              <PrivateRoute
                exact
                path={R.MODULE_STEPS_URL}
                Component={ModuleSteps}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.PARTICIPANT_ASSESSMENT}
                Component={ParticipantAssessment}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MY_ACCOUNT_URL}
                Component={MyAccount}
                allowedRoles={[
                  'admin',
                  'trainer',
                  'participant',
                  'programmeManager'
                ]}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.ANALYTICS_URL}
                Component={Analytics}
                allowedRoles={['admin', 'programmeManager', 'trainer']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.CHOOSE_PROGRAMME_FOR_SESSIONS}
                Component={ChooseProgrammeForSessions}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.DASHBOARD_URL}
                Component={Dashboard}
                allowedRoles={['admin', 'trainer', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path="/change-password"
                Component={ChangePassword}
                allowedRoles={['admin', 'trainer', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.SESSION_DETAILS_URL}
                Component={SessionDetails}
                allowedRoles={['admin', 'trainer', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.ADD_SESSION_URL}
                Component={UpsertSession}
                allowedRoles={['admin', 'trainer', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.EDIT_SESSION_URL}
                Component={UpsertSession}
                allowedRoles={['admin', 'trainer', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.SESSION_CREATED_URL}
                Component={SessionCreated}
                allowedRoles={['admin', 'trainer', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.SURVEY_QUESTIONS_URL}
                Component={Survey}
              />
              <PrivateRoute exact path={R.SURVEY_URL} Component={Survey} />
              <PrivateRoute
                exact
                path={R.ONBOARDING_SURVEY_QUESTIONS_URL}
                Component={Survey}
                isPrivate
                allowedRoles={'participant'}
              />
              <PrivateRoute
                path={R.MY_PROFILE_URL}
                Component={MyProfile}
                allowedRoles={['admin', 'trainer', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.LOGIN_URL}
                Component={Login}
                publicOnly
              />
              <PrivateRoute
                exact
                path={R.FORGET_PASSWORD}
                component={ForgetPassword}
                publicOnly
              />
              <PrivateRoute
                exact
                path={R.INVITATION_SIGN_UP_URL}
                component={InvitationSignUp}
              />
              <PrivateRoute
                exact
                path={R.PARTICIPANT_SIGN_UP_URL}
                component={ParticipantSignUp}
                publicOnly
              />
              <PrivateRoute
                exact
                path={R.UPDATE_INCOMPLETE_PROFILE}
                component={UpdateIncompleteProfile}
                allowedRoles={[
                  'admin',
                  'trainer',
                  'programmeManager',
                  'participant'
                ]}
              />
              <PrivateRoute
                path={R.PARTICIPANT_SESSION_DETAILS}
                Component={ParticipantSessionDetails}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.PARTICIPANT_LOGIN}
                Component={Login}
                publicOnly
                loginRole={userRoles.participant}
              />
              <PrivateRoute
                exact
                path="/participant/progress"
                Component={ParticipantProgress}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path="/certificate/:sessionId"
                Component={Certificate}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.ALL_SESSIONS_URL}
                Component={ViewSessions}
                allowedRoles={['trainer', 'admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MY_SESSIONS_URL}
                Component={ViewSessions}
                allowedRoles={['trainer', 'admin', 'programmeManager']}
              />
              <PrivateRoute
                exact
                path={R.TRAINER_SESSIONS_URL}
                Component={ViewSessions}
                allowedRoles={['trainer', 'admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path="/sessions-files"
                Component={SessionsFiles}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.PARTICIPANT_SESSIONS_LIST}
                Component={ParticipantSessions}
                allowedRoles={['participant']}
                isPrivate
              />
              <PrivateRoute
                publicOnly
                exact
                path={R.TRAINER_PUBLIC_SESSIONS}
                Component={TrainerSessions}
              />
              <PrivateRoute
                exact
                path={R.ACCESSIBILITY}
                Component={Accessibility}
              />
              <PrivateRoute
                exact
                path={R.MANAGE_PROGRAMMES_PROGRAMME_SELECTION}
                Component={ProgrammeSelection}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MANAGE_PROGRAMMES_PROGRAMME_OVERVIEW}
                Component={ProgrammeOverview}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES}
                Component={ProgrammeCategories}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES_ARCHIVED}
                Component={ProgrammeCategoriesArchived}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MANAGE_PROGRAMMES_PROGRAMME_EDIT}
                Component={ProgrammeEdit}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES_ADD}
                Component={ProgrammeCategoriesAddEdit}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MANAGE_PROGRAMMES_PROGRAMME_CATEGORIES_EDIT}
                Component={ProgrammeCategoriesAddEdit}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MANAGE_USERS}
                Component={ManageUsers}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MANAGE_USERS_EDIT}
                Component={ManageUsersAddEdit}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />
              <PrivateRoute
                exact
                path={R.MANAGE_USERS_ADD}
                Component={ManageUsersAddEdit}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={R.MANAGE_PROGRAMMES_PROGRAMME_MODULES}
                Component={ProgrammeModules}
                allowedRoles={['admin', 'programmeManager']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={R.INDIVIDUAL_EVENT_GROUP_BOOKING}
                Component={GroupBooking}
                allowedRoles={['participant']}
                isPrivate
              />

              <PrivateRoute
                exact
                path={R.UNSUBSCRIBE_NEWSLETTER_URL}
                Component={UnsubscribeNewsletter}
                publicOnly
              />

              <PrivateRoute
                exact
                path={R.EVENT_REGISTER_URL}
                Component={Register}
                publicOnly
              />

              <Route path="/404err" render={() => <NotFound />} />
              <Route path="/500err" render={() => <ServerError />} />
              <Route path="/unauthorized" render={() => <Unauthorized />} />
              <Route render={() => <NotFound />} />
            </Switch>
          </Router>
          {process.NODE_ENV === 'production' && (
            <CookieBot domainGroupId={domainGroupId} />
          )}
        </ThemeProvider>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  layoutFontFamily: state.accessibility.layoutFontFamily,
  isFontLarge: state.accessibility.isFontLarge
});

export default connect(mapStateToProps, {
  checkAuth,
  checkBrowserWidth,
  setLastActive
})(Sentry.withProfiler(App));
